<template>
  <!--
    Used to display a minified version of the devicesList.
  -->
  <div class="deviceListVisualisation">
    <LoadingPlaceholder v-if="loading" />
    <template v-else>
      <template v-if="devices">
        <ul>
          <li
            v-for="(device, index) in devices"
            :key="`deviceListVisualisation-deviceListItem-${ index }`"
          >
            <template v-if="!authenticationHasRole('zerolevel')">
              <router-link
                v-tooltip="{ content: device.name, placement: 'top' }"
                :to="deviceDetailLinkByUuid(device.uuid)"
                target="_self"
              >
                <font-awesome-icon
                  :class="`${ deviceIconColorByVpnIpAddress(device.vpnIpAddress) } fa-2x`"
                  :icon="`${ deviceIconByType(device.deviceType) }`"
                />
                <br>
                <span>{{ device.applicationType }}</span>
              </router-link>
            </template>
            <template v-else>
              <font-awesome-icon
                :class="`${ deviceIconColorByVpnIpAddress(device.vpnIpAddress) } fa-2x`"
                :icon="`${ deviceIconByType(device.deviceType) }`"
              />
              <br>
              <span>{{ device.applicationType }}</span>
            </template>
          </li>
        </ul>
        <div class="clearfix" />
      </template>
      <p v-else>
        {{ $t('noDataAvailable') }}
      </p>
    </template>
  </div>
</template>

<script>
import { authenticationMixin } from '@/mixins/authenticationMixin';

export default {
  name: 'DeviceListVisualisation',
  mixins: [
    authenticationMixin
  ],
  props: {
    installationId: {
      type: String,
      required: false,
      default: ""
    }
  },
  data () {
    return {
      loading: true,
      devices: null
    }
  },
  mounted () {
    this.getDevices();
  },
  methods: {
    //#region Helper
    deviceIconColorByVpnIpAddress (vpnIpAddress) {
      let hasVpnReturnValue = 'green';
      let hasNoVpnReturnValue = 'red';
      if (vpnIpAddress == null) {
        return hasNoVpnReturnValue;
      }
      if (vpnIpAddress.length == null) {
        return hasNoVpnReturnValue;
      }
      if (vpnIpAddress.length == 0) {
        return hasNoVpnReturnValue;
      }
      return hasVpnReturnValue;
    },
    deviceDetailLinkByUuid (uuid) {
      return `/installation/${ this.installationId }/devices/device/${ uuid }`;
    },
    shortenDeviceType (deviceType) {
      let indexOfMinus = deviceType.indexOf("-");
      if (indexOfMinus < 0) {
        return deviceType;
      }
      let shortenedDeviceType = deviceType.substring(0, indexOfMinus);
      return shortenedDeviceType;
    },
    deviceIconByType (deviceType) {
      if (deviceType == null) {
        return;
      }
      if (deviceType.length == null) {
        return;
      }
      if (deviceType.length == 0) {
        return;
      }
      switch (this.shortenDeviceType(deviceType)) {
        case 'odysseus':
          return 'microchip'; 
        case 'x86':
          return 'server'; 
        case 'mikrotik':
          return 'wifi'; 
        case 'odroid':
          return 'microchip'; 
        case 'webrelay':
          return 'charging-station'; 
        default:
          return 'network-wired'; 
      }
    },
    //#endregion

    //#region API-clls
    getDevices () {
      this.loading = true;
      this.axios.get(`/CentralDeviceManagement/GetFromInstallationId?installationId=${ this.installationId }`)
        .then((response) => {
          if (response == null) {
            return;
          }
          if (response.data == null) {
            return;
          }
          this.devices = response.data;
          this.$emit("showDestOs", this.devices.length > 0)
        })
        .finally(() => {
          this.loading = false;
        });
    }
    //#endregion
  }
}
</script>

<style scoped>
ul {
  margin: 0;
  padding: 0;
}
li {
  list-style: none;
  float: left;
  text-align: center;
  padding: 1rem;
}
li a {
  display: inline-block;
}
li a i {
  font-size: 2rem;
  margin-bottom: 0.5rem;
  display: inline-block;
}
li a span {
  display: inline-block;
  color: #3e3e4d;
  max-width: 100px;
}
li:hover a span {
  text-decoration: underline;
}
</style>
